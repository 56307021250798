@import "variables";

.wrapper-sm {
  display: flex;
  flex-direction: column;
}
.content-sm {
  height: calc(100vh - 168px);
  overflow: auto;
}

