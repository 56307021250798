@import "variables";

.same-lists {
  background-color: #ffffff;
  border-radius: 10px;
  padding-bottom: 10px;
  .list-header {
    padding: 20px 20px 15px;
    h1 {
      font-size: 22px;
      margin: 0;
      font-weight: bold;
    }
    button {
      padding: 11px 20px;
      font-size: $normal-font-size;
    }
    .list-type {
      padding: 5px;
      background-color: #E6F0FF;
      border-radius: 6px;
      button {
        width: 96px;
        padding: 6px 0;
        font-size: $base-font-size;
        color: #2B2E31 !important;
        background-color: #E6F0FF !important;
      }
      .active {
        color: white !important;
        background-color: $primary-color !important;
      }
    }
  }
  .payment-list-header {
    .list-type {
      background: none;
      padding: 0;
      border-radius: 0;
      border-bottom: 1px solid rgba(90, 100, 114, .5);
      margin-top: 20px;
      button {
        background: 0 !important;
        border-radius: 0 !important;
        font-size: $normal-font-size;
        padding-bottom: 12px;
        border-bottom: 2px solid #FFFFFF;
      }
      .active {
        color: $primary-color !important;
        background: none !important;
        border-color: $primary-color;
      }
    }
  }
  .table-responsive {
    width: 100%;
    min-height: 200px;
    overflow-x: auto;
  }
  table {
    width: 100%;

    .list-headings {
      margin: 15px 0 !important;
    }

    th {
      color: #5A6472;
      white-space: nowrap;
      font-weight: normal;
      padding: 7px 20px !important;
      border-bottom: 0.5px solid rgba(90, 100, 114, .5);
    }

    th, td {
      white-space: nowrap;
      padding: 7px 20px;
      height: 100%;
    }
    tr:hover:not(.list-headings) {
      background-color: rgba(16, 146, 209, .1);
    }
    tr td button {
      width: 87px;
      padding: 6px 0 !important;
      border-radius: 65px;
      font-size: $base-font-size;
      cursor: default !important;
    }
    tr td .btn-nill {
      color: #5A6472;
      background: #E7E7E7;
    }
    tr td .btn-primary {
      cursor: pointer !important;
    }
    tr td .btn-primary-light {
      cursor: pointer !important;
    }
    tr .more-icons {
      justify-content: flex-end;
      align-items: center;
      gap: 6px;
      button {
        width: 30px;
        border-radius: 100%;
        background-color: #1092D1;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer !important;
      }
      .msg-icon {
        background-color: #E5F0FF;
      }
      .eye-icon {
        background-color: #EAEAEA;
      }
    }
    .dropdown-menu {
      border: 0;
      box-shadow: 0px 2px 48px rgba(0, 0, 0, 0.08);
      border-radius: 4px;
    }

    .dropdown-btn {
      border: none;
      background-color: #EAEAEA !important;

      &:after {
        display: none;
      }

      &:hover {
        border: 0;
        background-color: transparent;
      }
    }
    .dropdown-item {
      color: $danger-color;
    }
    .btn-check:active + .btn-success, .btn-check:checked + .btn-success, .btn-success.active, .btn-success:active, .show > .btn-success.dropdown-toggle {
      background: transparent;
      border: none;
    }

    .btn-check:focus + .btn-success, .btn-success:focus {
      background: transparent;
      border: none;
      box-shadow: none;
    }

    .btn-check:active + .btn-success:focus, .btn-check:checked + .btn-success:focus, .btn-success.active:focus, .btn-success:active:focus, .show > .btn-success.dropdown-toggle:focus {
      box-shadow: none;
    }
    .msg-dropdown {
      padding: 10px !important;
      border-radius: 10px;
    }
    .msg-dropdown-item {
      padding: 0;
      label {
        font-size: $base-font-size;
        font-weight: 500;
        padding: 0;
      }
      .form-control {
        resize: none;
        margin: 12px 0;
        width: 240px;
        height: 106px;
        padding: 8px;
        font-size: $base-font-size;
      }
      button {
        width: auto;
        padding: 13px 24px;
      }
    }
  }

}

// Responsive
@media screen and (max-width: 600px) {
  .same-lists {
    .list-header-row {
      flex-direction: row !important;
    }
    .list-header {
      flex-direction: column;
      align-items: start !important;
      h1 {
        font-size: 18px;
      }
      .list-type {
        display: flex;
        width: 100%;
        margin-top: 16px;
        button {
          width: 100%;
        }
      }
    }
  }
}
