@import 'variables';

.products-section {
  background-color: #ffffff;
  border-radius: 10px;
  padding-bottom: 20px;

  .product-header {
    //font-size: ;
    padding: 20px 20px 22px;

    h1 {
      font-size: 22px;
      margin: 0;
    }

    button {
      padding: 11px 20px;
      font-size: $normal-font-size;
    }
  }

  .products {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    margin: 0 20px;

    .product {
      border: 1px solid #E6EBF1;
      border-radius: 16px;
      overflow: hidden;
      position: relative;

      .label {
        position: absolute;
        left: 0;
        top: 0;
        transform: translate(10px, 10px);
        background: rgb(170, 170, 170);
        border-radius: 5px;
        padding: 2px 5px;
        text-transform: capitalize;
        color: white;
        font-size: 11px;

        &[data-type="installable"] {
          background: #60A11E;
        }

        &[data-type="replaceable"] {
          background: #1092D1;
        }
      }


      img {
        width: 100%;
        height: 150px;
        object-fit: contain;
      }

      .details {
        padding: 12px;
        position: relative;

        .warranty-label {
          position: absolute;
          right: 0;
          top: 0;
          transform: translate(-10px, 10px);
          font-size: 11px;
          color: $primary-color;

        }

        .product-code {
          position: absolute;
          left: 0;
          top: 0;
          transform: translate(10px, 3px);
          font-size: 11px;
          color: $success-color;
        }

        h1 {
          font-size: $normal-font-size;
          margin: 0;
          padding: 10px 0 5px;
        }

        p {
          font-size: $base-font-size;
          margin: 0;
        }

        .btns {
          margin-top: 15px;

          span {
            font-size: 16px;
            font-weight: bold;
          }

          .current-price {
            font-size: $base-font-size;
            color: $primary-color;
            font-weight: bold;

            &:before {
              content: "Discounted Price ";
            }

            span {
              font-size: $normal-font-size;
              color: $success-color;
              font-weight: normal;
            }
          }

          .original-price {
            font-size: $base-font-size;
            color: $primary-color;
            font-weight: bold;

            &:before {
              content: "Price ";
            }

            span {
              font-size: $normal-font-size;
              color: $danger-color;
              font-weight: normal;
              text-decoration: line-through;

            }
          }

          .product-btns {
            display: flex;
            gap: 8px;
          }

          button {
            padding: 8px 15px;
            font-size: $base-font-size;
          }
        }
      }
    }
  }
}

.products-res {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;

  .product {
    height: 197px;
    background-color: #FFFFFF;
    padding: 10px;
    border-radius: 6px;

    img {
      height: 98px;
      width: 100%;
      border-radius: 6px;
    }

    h1 {
      font-size: $base-font-size;
      padding: 8px 0 10px;
      margin: 0;
    }

    span {
      font-size: 10px;
    }

    .current-price {
      font-size: $base-font-size;
      font-weight: 700;
      color: $primary-color;
      padding-left: 10px;
    }

    button {
      width: 100%;
      margin-top: 16px;
    }
  }
}
