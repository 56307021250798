@import "variables";
.chat-view {
  gap:10px;
  .chat-logs {
    width: 270px;
    box-shadow: 0px 4px 24px rgba(28, 83, 158, 0.1);
    border-radius: 10px;
    background-color: #FFFFFF;
    padding: 16px;
    .heading {
      h1 {
        font-size: 22px;
        font-weight: 700;
        margin: 0;
      }
      button {
        width: 100%;
        padding: 16px 0;
        margin: 24px 0;
      }
    }
    .csv-header {
      padding-top: 16px;
      border-top: 0.5px solid rgba(90, 100, 114, .5);
      h1 {
        font-size: 16px;
        margin: 0;
        padding-left: 6px;
      }
      button {
        padding: 8px 12px;
      }
    }
    .files {
      h1 {
        font-size: $base-font-size;
        padding: 6px 0;
        margin: 0;
      }
      .file {
        padding: 11px 10px;
        border-radius: 5px;
        &:hover {
          background-color: rgba(16, 146, 209, .1);
        }
        h1 {
          font-size: $normal-font-size;
          padding-left: 6px;
          margin: 0;
        }
      }
    }
  }
  .chat {
    flex-grow: 1;
    box-shadow: 0px 4px 24px rgba(28, 83, 158, 0.1);
    border-radius: 10px;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    .chat-header {
      padding: 18px 20px;
      border-bottom: 0.5px solid rgba(90, 100, 114, .5);
      h1 {
        color: #2B2E31;
        font-size: 14px;
        margin: 0;
        padding-left: 6px;
      }
    }
    .chat-msgs-container {
      flex-grow: 1;
      padding: 16px;
      .msg {
        background-color: rgba(90, 100, 114, .1);
        padding: 10px;
        border-radius: 6px;
        p {
          font-size: $normal-font-size;
          color: #2B2E31;
          margin: 0;
        }
      }
    }
    .new-msg {
      padding: 0 16px 10px;
      .combine-input {
        padding: 5px 5px 5px 20px;
        input {
          padding: 0 !important;
          background-color: #E5F0FF !important;
        }
        button {
          padding: 9px;
        }
      }
    }
  }
}
