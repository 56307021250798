@import "variables";

.payments-sm {
  .payment-type {
    display: flex;
    button {
      width: 100%;
      padding: 15px 0;
      font-size: $normal-font-size;
      border-radius: 0;
    }
    .active {
      background-color: $success-color;
      color: white;
    }
  }
}
