@import 'variables';

.header {
  height: 70px;
  display: flex;
  justify-content: flex-end;
  padding-bottom: 20px;
  .search-bar {
    //padding: 14px 26px;
    .combine-input {
      width: 100%;
      input {
        padding: 0;
        border-radius: 0;
        &:focus {
          background-color: #E5F0FF !important;
        }
      }
      .btn {
        padding: 0;
        &:focus {
          outline: none;
          border: 0;
          box-shadow: none;
        }
      }
      img {
        cursor: pointer;
      }
    }
  }
  .notification-point {
    display: flex;
    align-items: center;
    padding: 0 0 0 15px;

    .noti-icons {
      cursor: pointer;
      position: relative;

      .active-noti {
        width: 5px;
        height: 5px;
        background-color: #FF2121;
        position: absolute;
        top: -1px;
        right: -1px;
        border-radius: 100px;
      }
    }
  }

  .dropdown-menu {
    border: 0;
    box-shadow: 0 2px 48px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    padding: 0;
    overflow: hidden;
  }

  .dropdown-btn {
    background-color: transparent;
    border: none;

    &:after {
      display: none;
    }

    &:hover {
      border: 0;
      background-color: transparent;
    }
  }
  .user-dropdown-menu {
    padding: 10px 0 !important;
  }
  .user-dropdown-item {
    border-radius: 4px !important;
    padding: 0 15px !important;
    color: black;
  }
  .dropdown-item {
    max-width: 386px;
    display: flex;
    padding: 0;
    &:hover {
      background-color: transparent;
    }
    .heading h1 {
      padding: 9px 12px;
      font-size: 16px;
      margin: 0;
      font-weight: 700;
      cursor: default;
    }
    .notification {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 6px;
      padding: 6px 12px;
      img {
        width: 40px;
        border-radius: 100%;
      }
      h1 {
        font-size: $base-font-size;
        margin: 0;
        color: #5A6472;
        span {
          font-weight: 600;
          color: #2B2E31 !important;
        }
      }
      p {
        margin: 0;
        color: #5A6472;
        font-size: $base-font-size;
      }
      &:hover {
        background-color: rgba(16, 146, 209, .1);
      }
    }
    .more {
      width: 100%;
      padding: 5px 0;
      text-align: center;
      background-color: rgba(16, 146, 209, .2);
      a {
        font-size: $base-font-size;
        text-decoration: none;
        color: #5A6472;
      }
    }
  }

  .btn-check:active + .btn-success, .btn-check:checked + .btn-success, .btn-success.active, .btn-success:active, .show > .btn-success.dropdown-toggle {
    background: transparent;
    border: none;
  }

  .btn-check:focus + .btn-success, .btn-success:focus {
    background: transparent;
    border: none;
    box-shadow: none;
  }

  .btn-check:active + .btn-success:focus, .btn-check:checked + .btn-success:focus, .btn-success.active:focus, .btn-success:active:focus, .show > .btn-success.dropdown-toggle:focus {
    box-shadow: none;
  }

  .user {
    .user-name {
      padding-left: 8px;
    }

    .profile {
      width: 44px;
      height: 44px;
      border: 4px solid #E5F0FF;
      border-radius: 100%;
      object-fit: cover;
    }

    h1 {
      font-size: $base-font-size;
      margin-bottom: 3px;
      color: #2B2E31;
      font-weight: bold;
    }

    p {
      font-size: 12px;
      margin: 0;
      color: #8898A7;
      text-align: start;

      img {
        border: 0;
        padding-left: 8px;
      }
    }
  }
}

