@import 'variables';
.side-nav {
  width: 70px;
  height: 100vh;
  background: $primary-color;
  box-shadow: 4px 0px 12px rgba(0, 0, 0, 0.1);
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  .logo {padding-top: 20px}
  .nav-links {
    flex-grow: 1;
    padding: 0;
    margin: 0;
    gap: 11px;
      a {
        width: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-decoration: none;
        text-align: center;
        font-size: $base-font-size;
        padding: 15px 0;
        color: white;
        border-radius: 10px;
        &:hover {
          background-color: rgba(229, 240, 255, .1);
        }
      }
      .active {
        background-color: #E5F0FF !important;
        color: $primary-color;
      }
  }
  .logout {
    margin-bottom: 10px;
  }
  .logout .btn {
    background: rgba(255, 255, 255, 0.07);
    border-radius: 10px;
    padding: 16px;
  }
}
