@import "variables";

.new-buyer-sm {
  padding: 16px;
 form {
   button {
     width: 100%;
     padding: 15px 0;
   }
 }
}
