@import "variables";

.navbar-sm {
  height: 88px;
  background-color: white;
  .nav-links {
    height: 100%;
    padding: 0 20px;
    margin: 0;
    a {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-decoration: none;
      text-align: center;
      font-size: $base-font-size;
      color: #5A6472;
      img {
        position: absolute;
        bottom: 59px;
        border: 5px solid #D4E0EE;
        border-radius: 100%;
        outline: #FFFFFF solid 5px;
      }
    }
    .pr {
      padding-right: 30px;
    }
    .pl {
      padding-left: 30px;
    }
    .active {
      color: $primary-color;
      .title {
        color: #2B2E31 !important;
      }
    }
  }
}
