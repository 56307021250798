@import "variables";

.change-settings-sm {
  padding: 16px;
  .user {
    .photos {
      background-image: url("../mobileIcons/cover-placeholder.svg");
      text-align: center;
      img {
        border-radius: 100%;
        border: 10px solid #FFFFFF;
      }
    }
    .user-details {
      text-align: center;
      button {
        padding-top: 6px;
        font-size: $base-font-size;
        color: $primary-color;
      }
    }
  }
  form {
    button {
      padding: 18px 0;
      width: 100%;
    }
  }
}
