@import "variables";

.modal {
  .payment-details-modal {
    .client-info {
      h1 {
        font-size: 22px;
        font-weight: 700;
        margin: 0;
      }
      p {
        color: #2B2E31;
        font-size: $normal-font-size;
        padding-left: 6px;
        margin: 0;
      }
      .pending-payment {
        padding: 16px 24px;
        background-color: $danger-color;
        box-shadow: 0px 4px 24px rgba(28, 83, 158, 0.1);
        border-radius: 10px;
        margin-top: 16px;
        color: white;
        h1 {
          font-size: 16px;
          font-weight: 500;
        }
        p {
          padding: 0;
          color: white ;
        }
      }
    }
    .payment-info {
      background: #F3F4F5;
      border-radius: 20px;
      padding: 20px;
      margin: 20px 0 24px;
      span {
        font-size: $normal-font-size;
        color: #2B2E31;
      }
      .dark {
        color: #5A6472;
      }
      .pending-payment {
        padding: 16px 24px;
        background-color: #2B2E31;
        box-shadow: 0px 4px 24px rgba(28, 83, 158, 0.1);
        border-radius: 10px;
        margin: 30px 0 20px;
        color: white;
        h1 {
          font-size: 16px;
          font-weight: 500;
          margin: 0;
        }
        p {
          margin: 0;
          padding: 0;
          color: white ;
        }
      }
      .paid-payments {
        background-color: $success-color;
        border-radius: 10px;
        .bt {
          border-top: 1px dashed #FFFFFF;
          padding: 12px 15px 20px !important;
        }
        .paid-payment {
          padding: 20px 15px 12px;
          color: #FFFFFF;
          h1 {
            font-size: 16px;
            font-weight: 500;
            margin: 0;
          }
          p {
            margin: 0;
            padding: 0;
            color: white ;
          }
        }
      }
      .all-payment {
        padding-top: 16px;
        p {
          font-size: $normal-font-size;
          color: #5A6472;
          margin: 0;
        }
      }
    }
    .full-details {
      margin: 0;
      .pending-payment {
        margin-top: 0;
      }
      table {
        margin: 13px 0 10px;
        width: 100%;
        box-shadow: 0 4px 24px rgba(28, 83, 158, 0.1);
        border-radius: 10px;
        overflow: hidden;

        .list-headings {
          color: $primary-color !important;
          background: #E5F0FF;
        }

        th {
          color: $primary-color;
          white-space: nowrap;
          font-weight: normal;
        }
        td {
          color: #5A6472;
        }
        th, td {
          font-size: $normal-font-size;
          padding: 12px 16px;
        }
        td {
          background: white;
        }
      }
    }
  }
}

