@import "variables";

.payment-details-sm {
  padding: 16px;
  .list-header {
    h1 {
      font-size: 16px;
      margin: 0;
      font-weight: bold;
      padding-bottom: 12px;
    }
  }
  .photos {
    gap: 10px;
    padding-bottom: 20px;
    img {
      width: 140px;
      border-radius: 6px;
    }
  }
  table {
    width: 100%;
    box-shadow: 0px 4px 24px rgba(28, 83, 158, 0.1);
    border-radius: 6px;
    overflow: hidden;

    .list-headings {
      color: $primary-color !important;
      background: #E5F0FF;
    }

    th {
      color: $primary-color;
      white-space: nowrap;
      font-weight: normal;
      border-bottom: 0.5px solid rgba(90, 100, 114, .5);
    }
    td {
      color: #5A6472;
    }
    th, td {
      font-size: $base-font-size;
      padding: 12px 16px;
    }
    td {
      background: white;
    }
  }
  .payment-total, .sub-total, .paid-info, .next-payment {
    color: #2B2E31;
    font-size: $base-font-size;
    background: #FFFFFF;
    padding: 12px 16px;
    border-radius: 6px;
    margin-top: 6px;
  }
  .sub-total {
    background: #2B2E31;
    color: white;
  }
  .add-payment {
    margin-top: 16px;
    div {
      gap: 10px;
      input {
        padding: 15px 20px;
      }
      button {
        padding: 0 30px;
      }
    }
  }
  .paid-info {
    margin-top: 16px;
    //background-image: url("../mobileIcons/payments-bg.svg");
    background: #6CC061;
    color: #FFFFFF;
    padding: 0;
    .paid {
      padding: 20px 16px 12px;
    }
    .pending {
      padding: 12px 16px 20px;
      border-top: 1px dashed #FFFFFF;
    }
  }
  .next-payment {
    padding: 0;
    margin-top: 16px;
    background: transparent;
    button {
      width: 100%;
      padding: 15px 0 !important;
    }
  }
}
