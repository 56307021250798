
@import "variables";
@import "buttons";
@import "inputs";
@import "main";
@import "splash";
@import "login";
@import "navbar";
@import "header";
@import "home";
@import "lists";
@import "modals";
@import "products";
@import "chat";
@import "tooltip";
@import "payments";
@import "payment-details";
@import "list-card";
@import "edit-profile";

* {
  margin: 0;
  padding: 0;
}
body {
  width: 100vw;
  height: 100vh;
  font-family: Montserrat, 'sans-serif';
  background-color: #F6FAFA;
}


