$base-font-size: 12px;
$normal-font-size: 14px;
$btn-border-radius: 130px;
:root {
  --primary-color: #1092D1;
  --success-color: #60A11E;
  --danger-color: #D11313;
  --warning-color: #E49F36
}

$primary-color: var(--primary-color);
$danger-color: var(--danger-color);
$success-color: var(--success-color);
$warning-color:  var(--warning-color)
