@import "variables";

.edit-profile-sm {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 16px;
  .user {
    background: #ffff;
    padding: 16px;
    border-radius: 10px;
    .photos {
      background-image: url("../mobileIcons/cover-placeholder.svg");
      text-align: center;
      img {
        border-radius: 100%;
        border: 10px solid #FFFFFF;
      }
    }
    .user-details {
      text-align: center;
      h1 {
        padding-top: 6px;
        margin: 0;
        font-size: 16px;
      }
      p {
        font-size: $normal-font-size;
        margin: 0;
        padding: 2px 0 16px;
      }
      button {
        padding: 15px 0;
        width: 100%;
        color: $primary-color;
        a {
          text-decoration: none;
          color: $primary-color;
        }
      }
    }
  }
  .user-tasks {
    padding: 16px;
    border-radius: 10px;
    background: #FFFFFF;
    .tasks {
      h1 {
        font-size: 16px;
      }
      .task-type {
        display: flex;
        gap: 12px;
        .task {
          box-shadow: none;
          width: 100%;
          padding: 12px;
          margin: 0;
          img {
            width: 30px;
            height: 30px;
          }
          .today-task-icon {
            background: linear-gradient(2.51deg, #60BD62 15.35%, #80C45F 103.69%);
            border-radius: 6px;
            padding: 7px;
            width: 32px;
            height: 32px;
          }
          p {
            margin: 0;
          }
          h1 {
            margin: 0;
          }
        }
        .btn-primary-light-sm {
          border: 0 !important;
          background: #E5F0FF !important;
        }
      }
    }
  }
  .user-payments {
    padding-top: 30px;
    h1 {
      font-size: 16px;
    }
    .payment-type {
      display: flex;
      flex-direction: column;
      gap: 6px;
      .payment {
        border-radius: 6px;
        padding: 13px 16px;
        p {
          color: #5A6472;
          margin: 0;
        }
        h1 {
          margin: 0;
        }
      }
      .btn-primary-light-sm {
        border: 0 !important;
        background: #E5F0FF !important;
      }
    }
  }
}
