@import "variables";

.header-sm {
  padding: 0 30px 0 20px;
  height: 80px;
  background-color: $primary-color;
  .user {
    h1 {
      color: white;
      margin: 0 0 0 8px;
      font-size: 16px;
    }
    p {
      font-size: $normal-font-size;
      color: #DFE6EF;
      padding-top: 5px;
      margin: 0 0 0 8px;
    }
  }
}
