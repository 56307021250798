@import "variables";
.payments-view {
  .filters {
    gap: 6px;
    padding: 0 16px 16px;
    .combine-input {
      height: 50px;
      width: 250px;
      padding: 0 16px;
      input {
        padding: 0;
        background: #E5F0FF !important;
        padding-left: 10px;
      }
      button {
        padding: 0;
      }
    }
    .dropdown-select {
      height: 50px;
      width: 250px;
      background: #E5F0FF;
      border-color: #E5F0FF;
      color: #5A6472;
      display: inline-flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
      .spacer {
        flex-grow: 1;
      }
      &:after {
        content: unset;
      }

      &:hover {
        background: #E5F0FF;
        border-color: #E5F0FF;
        color: #5A6472;

      }

      &.dropdown-toggle {
        background: #E5F0FF !important;
        border-color: #E5F0FF !important;
        color: #5A6472 !important;
        outline: unset;
        box-shadow: unset;
      }

      &:focus {
        outline: unset !important;
        box-shadow: unset !important;
      }
    }
    .search-btn {
      padding: 11px 35px;
    }
  }
}
