@import 'variables';

.form-control {
  border-radius: $btn-border-radius;
  background-color: #E6F0FF !important;
  padding: 14px 20px;
  box-shadow: none !important;
  border: 1px solid #E6F0FF !important;
}
label {
  color: #2B2E31;
  padding-bottom: 6px;
  margin: 0 !important;
}
input::placeholder {
  color: #687588 !important;
}

.form-control:focus {
  background-color: white !important;
  border-color: #C0D6F3;
}

.media-file {
  h1 {
    font-size: $normal-font-size !important;
    color: #2B2E31;
    padding-bottom: 6px;
    margin: 0 !important;
    font-weight: normal !important;
  }
  .media {
    background: #E5F0FF;
    border: 1px dashed $primary-color;
    border-radius: 6px;
    padding-bottom: 20px;
    text-align: center;
    .added-imgs {
      display: flex;
      gap: 6px;
      text-align: start;
      padding: 12px;
      .added-img {
        position: relative;
        .cross-icon {
          position: absolute;
          padding: 0;
          top: 5px;
          right: 5px;
          background-color: #00C1F3;
          border-radius: 50px;
          cursor: pointer;
        }
        .file {
          width: 100px;
          object-fit: cover;
          height: 100px;
          border-radius: 10px;
        }
      }
    }
    h1 {
      font-size: $base-font-size;
      color: #2B2E31;
      padding: 20px 0 0;
    }
    p {
      margin: 0;
      font-size: 10px;
      color: #727171;
      padding-top: 7px;
    }
    label {
      cursor: pointer;
      background-color: $primary-color;
      padding: 12px 24px;
      color: white;
      border-radius: 6px;
      margin-top: 8px !important;
    }
  }
}
