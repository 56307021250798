@import "variables";

.edit-profile {
  max-width: 648px;
  form {
    background-color: #FFFFFF;
    padding: 16px;
    box-shadow: 0 4px 24px rgba(28, 83, 158, 0.1);
    border-radius: 10px;
    h1 {
      font-size: 22px;
      font-weight: 700;
    }
    .file-input-section {
      padding: 20px 0;
      .placeholder-img {
        width: 140px;
        height: 140px;
        border-radius: 20px;
        object-fit: cover;
      }
      .file-input {
        position: relative;
        .file-label {
          position: absolute;
          top: -23px;
          left: 120px;
          img {
            cursor: pointer;
            background-color: #E5F0FF;
            box-shadow: 0 2px 48px rgba(0, 0, 0, 0.08);
            padding: 12px;
            border-radius: 100px;
          }
        }
      }
    }
    button {
      padding: 12px 42px;
    }
  }
}

// Responsive
@media screen and (max-width: 600px) {
  .edit-profile {
    width: 100%;
  }
}
