.aqua-tooltip {
  position: relative;

  &:not(:hover) {
    &:after {
      content: unset;
    }

    &:before {
      content: unset;
    }
  }

  &:after {
    content: attr(data-tooltip);
    position: absolute;
    padding: 5px;
    border-radius: 5px;
    background-color: black;
    color: white;
    left: 100%;
    bottom: 50%;
    transform: translate(10px, 50%);
  }

  &:before {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 2px;
    background-color: black;
    left: 100%;
    bottom: 50%;
    transform: translate(7px, 50%) rotate(45deg);
  }
}
