@import "variables";

.btn {
  box-shadow: none !important;
  border: 0;

}
.btn-primary-light {
  border: 1px solid $primary-color !important;
  color: $primary-color !important;
  border-radius: 6px !important;
  background: #FFFFFF;
}
.btn-primary {
  background-color: $primary-color !important;
  color: white !important;
  border-radius: 6px !important;
  font-size: $base-font-size;
}
.btn-success {
  color: $success-color !important;
  background-color: rgba(247, 251, 243, 1) !important;
}
.btn-danger {
  color: $danger-color !important;
  background-color: rgba(252, 243, 243, 1) !important;
}
.btn-warning {
  color: $warning-color !important;
  background-color: #FCF5EB !important;
}
