@import "variables";

.login {
  width: 100%;
  height: 100vh;
  background-color: $primary-color;
  form {
    width: 500px;
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(28, 83, 158, 0.1);
    border-radius: 10px;
    padding: 24px;
    h1 {
      font-size: 24px;
      font-weight: 700;
    }
    .more-opt {
      font-size: $normal-font-size;
      div {
        cursor: pointer;
      }
      p {
        margin: 0;
        color: #2B2E31;
        padding-left: 10px;
      }
      a {
        text-decoration: none;
        color: $primary-color;
      }
    }
    button {
      width: 100%;
      padding: 17px 0;
      font-size: $normal-font-size;
    }
  }
}
