@import "variables";

.login-sm {
  padding: 70px 0;

  .text-center {
    padding: 0 35px;

    h1 {
      padding-top: 30px;
      font-size: 24px;
      margin: 0;

      span {
        font-weight: 700;
      }
    }

    p {
      margin: 0;
      padding-top: 6px;
      padding-bottom: 28px;
    }
  }

  form {
    margin: 0 30px;

    button {
      width: 100% !important;
      padding: 20px 135px;
      font-size: 16px;
      margin-top: 15px;
    }

    .multiple-inputs {
      margin: 0 30px;
      gap: 10px;


      .form-control {
        text-align: center;
        font-size: 18px;
      }
    }
  }

  p {
    color: #5A6472;
    font-size: 16px;
  }

  a {
    text-decoration: none;
    margin-top: 20px;
    text-align: center;
    color: $primary-color;
    font-size: 16px;
  }
}
