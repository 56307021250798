@import "variables";

.filters {
  gap: 10px;
  .combine-input {
    width: 100% !important;
    input {
      width: 100% !important;
    }
  }
  .btn-primary {
    padding: 12px 16px !important;
  }
}
.list-cards {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.list-card {
  margin: 0 16px;
  border: 1px solid #A8ABB0;
  border-radius: 8px;
  padding: 12px;
  h1 {
    font-size: $base-font-size;
    margin: 0;
    color: #2B2E31;
    span {
      color: #5A6472;
    }
  }
  .location {
    padding: 10px 0;
    img {
      padding-right: 5px;
    }
  }
  p {
    font-size: $base-font-size;
    color: #5A6472;
    margin: 0;
  }
  .badge-custom {
    width: 87px;
    padding: 6px 0;
    font-size: $base-font-size;
    border-radius: 65px;
    margin-left: 16px;
    cursor: default;
  }
  .btn-nill {
    color: #5A6472;
    background: #E7E7E7;
  }
  .btns {
    gap: 10px;
    margin-top: 16px;
    .btn-primary-light {
      width: 100%;
    }
    .btn-primary {
      width: 100%;
    }
    .msg-icon {
      background-color: #E5F0FF;
    }
    .eye-icon {
      background-color: #EAEAEA;
    }
    .dropdown-menu {
      border: 0;
      box-shadow: 0px 2px 48px rgba(0, 0, 0, 0.08);
      border-radius: 4px;
    }

    .dropdown-btn {
      padding: 0;
      border: none;
      background-color: #EAEAEA !important;

      &:after {
        display: none;
      }

      &:hover {
        border: 0;
        background-color: transparent;
      }
    }
    .dropdown-item {
      color: $danger-color;
    }
    .btn-check:active + .btn-success, .btn-check:checked + .btn-success, .btn-success.active, .btn-success:active, .show > .btn-success.dropdown-toggle {
      background: transparent;
      border: none;
    }

    .btn-check:focus + .btn-success, .btn-success:focus {
      background: transparent;
      border: none;
      box-shadow: none;
    }

    .btn-check:active + .btn-success:focus, .btn-check:checked + .btn-success:focus, .btn-success.active:focus, .btn-success:active:focus, .show > .btn-success.dropdown-toggle:focus {
      box-shadow: none;
    }
    .msg-dropdown {
      padding: 10px !important;
      border-radius: 10px;
    }
    .msg-dropdown-item {
      padding: 0;
      label {
        font-size: $base-font-size;
        font-weight: 500;
        padding: 0;
      }
      .form-control {
        resize: none;
        margin: 12px 0;
        width: 240px;
        height: 106px;
        padding: 8px;
        font-size: $base-font-size;
      }
      .btn-primary {
        width: auto;
        padding: 13px 24px;
      }
    }
  }
}
