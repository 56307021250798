@import "variables";

.modal-container-sm {
  width: 100%;
  padding: 16px;
  background-color: white;
  border-radius: 15px;
  position: relative;
  .close-modal {
    position: absolute;
    top: 11px;
    right: 15px;
    cursor: pointer;
  }
  form {
    h1 {
      margin: 0;
      text-align: center;
      font-size: 36px;
      padding-top: 16px;
    }
    p {
      text-align: center;
      font-size: 16px;
      color: #5A6472;
    }
  }
  p {
    margin: 0;
  }
  .add-payment-btn {
    button {
      width: 100%;
    }
  }
  .btns {
    margin-top: 24px;
    gap: 10px;
    button {
      width: 100%;
    }
    .cancel-btn {
      border: 1px solid $danger-color;
      color: $danger-color;
    }
  }
  .heading {
    h1 {
      margin: 0;
      font-size: 16px;
      color: #2B2E31;
      padding-bottom: 16px;
    }
  }
  .filters-sm {
    gap: 16px;
    .combine-input {
      height: 50px;
      width: 100%;
      padding: 0 16px;
      input {
        padding: 0;
        background: #E5F0FF !important;
        padding-left: 10px;
      }
      button {
        padding: 0;
      }
    }
    .dropdown-select {
      height: 50px;
      width: 350px;
      background: #E5F0FF;
      border-color: #E5F0FF;
      color: #5A6472;
      display: inline-flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
      .spacer {
        flex-grow: 1;
      }
      &:after {
        content: unset;
      }

      &:hover {
        background: #E5F0FF;
        border-color: #E5F0FF;
        color: #5A6472;
      }
      &.dropdown-toggle {
        background: #E5F0FF !important;
        border-color: #E5F0FF !important;
        color: #5A6472 !important;
        outline: unset;
        box-shadow: unset;
      }

      &:focus {
        outline: unset !important;
        box-shadow: unset !important;
      }
    }
    .search-btn {
      width: 100px;
      padding: 17px 24px;
      margin-top: 8px;
    }
  }
}
