@import "variables";
.wrapper {
  display: flex;
  overflow: hidden;
}

.main-content {
  flex-grow: 1;
  padding: 20px;
  width: calc(100vw - 70px);
}

.content {
  height: calc(100vh - 200px);
  border-radius: 10px;
  overflow-y: auto;
  overflow-x: auto;
}

.error {
  color: $danger-color;
  font-size: $base-font-size;
}

