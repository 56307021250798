@import "variables";

.payments {
  margin-top: 10px;
  .payment {
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(28, 83, 158, 0.1);
    border-radius: 10px;
    margin: 0 16px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    //padding: 16px;
    position: relative;
    overflow: hidden;
    .task-date {
      position: absolute;
      top: 0;
      right: 0;
      width: 90px;
      border-radius: 0 0 0 10px;
      padding: 7px 0;
    }
    .details {
      padding: 16px 16px 0;
      h1 {
        font-size: 16px;
        margin: 0;
      }
      p {
        font-size: $normal-font-size;
        margin: 0;
        padding-top: 4px;
        color: #5A6472;
      }
      .location {
        padding-top: 10px;
        p {
          font-size: 14px;
          padding-left: 7px;
          margin: 0;
        }
      }
    }
    .payment-dues {
      div {
        padding: 20px 16px 16px;
        background-color: #2B2E31;
        color: white;
        font-size: 16px;
      }
      div:last-child {
        border-top: 1px dashed white;
      }
    }
    .btns {
      gap: 10px;
      padding: 0 16px 16px;
      button {
        font-size: 14px;
        padding: 13px 0;
      }
      .direction-btn {
        padding: 13px;
        border: 1px solid $primary-color;
        color: $primary-color;
      }
      .btn-primary {
        padding: 13px 15px !important;
      }
      .phone-btn {
        padding: 13px;
        background-color: $success-color;
      }
    }
  }
}
