@import "variables";

@import 'variables';
.modal {
  .modal-content {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    background: none;
    border: none;
    border-radius: 0;
    outline: 0;
  }
  .modal-container {
    width: 564px;
    padding: 24px;
    background-color: white;
    border-radius: 15px;
    position: relative;
  }
  .close-modal {
    position: absolute;
    top: 11px;
    right: 15px;
    cursor: pointer;
  }
  h1 {
    font-size: 22px;
    font-weight: 700;
    margin: 8px 0 0;
  }
  .combine-input {
    input {
      width: 100%;
      outline: none;
      border: none;
      background-color: #E5F0FF;
      &:focus {
        background-color: #E5F0FF !important;
      }
    }
    span {
      color: $danger-color;
      cursor: pointer;
    }
  }
  button {
    padding: 17px 24px;
    font-size: $normal-font-size;
  }
}
.danger-btns {
  display: flex;
  justify-content: center;
  margin-top: 25px;
  gap: 20px;
  .cancel-btn {
    background-color: white;
    color: black;
    border: 1px black solid;
  }
  button {
    background-color: #ff6161;
    color: white;
    padding: 10px 20px;
  }
}


@media screen and (max-width: 600px) {
  .modal {
    .modal-container {
      width: 396px;
    }
  }
}
