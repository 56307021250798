@import "variables";

.form-control {
  border-radius: 6px;
  border: 0;
  background-color: #E6F0FF !important;
  padding: 15px 20px;
  box-shadow: none !important;
  font-size: $normal-font-size;
}
label {
  color: #2B2E31;
  padding-bottom: 6px;
  margin: 0 !important;
  font-size: $normal-font-size;
}
input::placeholder {
  color: #687588 !important;
}
