@import "variables";

.notifications-sm {
  padding: 16px;
  display: flex;
  gap: 10px;
  .notification {
    width: 100%;
    height: 72px;
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(28, 83, 158, 0.1);
    border-radius: 10px;
    padding: 10px 15px;
    img {
      width: 50px;
      height: 50px;
      box-shadow: 0px 4px 24px rgba(28, 83, 158, 0.1);
      border-radius: 160px;
    }
    .details {
      padding-left: 6px;
      h1 {
        font-size: $normal-font-size;
        color: #5A6472;
        margin: 0;
      }
      h2 {
        font-size: $normal-font-size;
        color: #2B2E31;
        margin: 0;
      }
      p {
        font-size: $base-font-size;
        color: #5A6472;
        margin: 0;
      }
    }
  }
}
