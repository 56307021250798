@import "variables";

.new-products-sm {
  padding: 16px;
  .combine-input-sm {
    padding: 12px 15px;
    input {
      padding: 0;
      background-color: #E6F0FF !important;
    }
    .btn {
      padding: 0;
    }
  }
  h2 {
    font-size: 16px;
    color: #2B2E31;
    margin: 0;
    padding: 16px 0 8px;
  }
  .products {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    .product {
      height: 197px;
      background-color: #FFFFFF;
      padding: 10px;
      border-radius: 6px;
      img {
        height: 98px;
        width: 100%;
        border-radius: 6px;
      }
      h1 {
        font-size: $base-font-size;
        padding: 8px 0 10px;
        margin: 0;
      }
      span {
        font-size: 10px;
      }
      .current-price {
        font-size: $base-font-size;
        font-weight: 700;
        color: $primary-color;
        padding-left: 10px;
      }
      button {
        width: 100%;
      }
      .qty-btn {
        margin-top: 5px;
        border: 1px solid $primary-color;
        padding: 5px;
        .qty {
          color: $primary-color;
          background: #F7FBF3;
          padding: 0 7px;
        }
      }
    }
  }

  .add-payment-btn {
    margin-top: 10px;
    width: 100%;
    padding: 15px 0;
    a {
      color: white;
      text-decoration: none;
    }
  }
}

