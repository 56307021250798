@import "variables";

.home-sm {
  .top-section {
    background: white;
    padding-top: 16px;
  }
  .active-task{
    background: $warning-color;
    margin: 0 16px;
    padding: 16px;
    border-radius: 6px;
    h1 {
      font-size: 16px;
      margin: 0;
      color: white;
      font-weight: normal;
    }
    p {
      font-size: $normal-font-size;
      color: white;
      margin: 0;
      font-weight: 200;
    }
    button {
      width: 100%;
      background: white;
      padding: 12px 0;
      border-radius: 6px;
      margin-top: 15px;
      a {
        color: #2B2E31;
        text-decoration: none;
      }
    }
  }
  .task-type {
    background: white;
    padding: 12px 20px;
    display: flex;
    gap: 9px;
    button {
      padding: 10px 12px;
      font-size: $normal-font-size;
    }
    .all {
      color: $primary-color !important;
      background-color: #E5F0FF !important;
    }
    .activeAll {
      color: #FFFFFF !important;
      background: linear-gradient(356.53deg, #1092D1 14.95%, #00C1F3 93.11%);
    }
    .activeToday {
      background-color: $success-color !important;
      color: #FFFFFF !important;
    }
    .activeUpcoming {
      background-color: $warning-color !important;
      color: #FFFFFF !important;
    }
    .activePending {
      background-color: $danger-color !important;
      color: #FFFFFF !important;
    }
  }
}
