@import "variables";

.home  {
  .payment-cards {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 15px;
    .primary-bg { background-color: rgba(16, 146, 209, .1) !important;}
    .success-bg {background-color: rgba(96, 161, 30, .1) !important;}
    .light-bg {background-color: rgba(90, 100, 114, .1) !important;}
    .warning-bg {background-color: rgba(228, 159, 54, .1) !important;}
    .payment-card {
      width: 100%;
      height: 160px;
      background-color: rgba(16, 146, 209, .1);
      border-radius: 20px;
      padding: 18px 18px 32px 22px;
      .details {
        h1 {
          margin: 0;
          font-size: 18px;
          font-weight: 700;
          padding-top: 12px;
        }
        h2 {
          font-weight: 700;
          margin: 0;
          font-size: 30px;
          padding-top: 25px;
        }
      }
      .percent {
        img:first-child {
          width: 23px;
          height: 23px;
          padding-bottom: 7px;
        }
      }
    }
  }
}

@media screen and (max-width: 1150px){
  .payment-cards {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}


@media screen and (max-width: 600px){
  .payment-cards {
    grid-template-columns: 1fr !important;
  }
}
