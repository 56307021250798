@import "variables";

.new-items-sm {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  .take-img {
    background: url("../mobileIcons/add-img-bg.svg");
  }
  .add-products {
    background: url("../mobileIcons/add-product-bg.svg");
  }
  .add-some {
    text-decoration: none;
    padding: 55px 0;
    text-align: center;
    color: white;
    h1 {
      font-size: 26px;
      margin: 0;
    }
    p {
      font-size: $normal-font-size;
      margin: 0;
    }
  }
}
