@import "main";
@import "splash";
@import "inputs";
@import "buttons";
@import "modals";
@import "login";
@import "header";
@import "navbar";
@import "home";
@import "task";
@import "products";
@import "add-items";
@import "payments";
@import "edit-profile";
@import "change-settings";
@import "payments-details";
@import "add-products";
@import "new-buyer";
@import "single-payment";
@import "notifications";

* {
  margin: 0;
  padding: 0;
  font-family: Poppins, 'sans-serif';
}

body {
  width: 100vw;
  height: 100vh;
  background-color: #F6FAFA;
}
